/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import ApplicationLayout from 'components/EntryLayouts/ApplicationLayout';
import AuthorizationLayout from 'components/EntryLayouts/AuthorizationLayout';
import AccountRegistration from 'components/Registration/Account';
import SuccessAccountRegistration from 'components/Registration/Account/SuccessAccountRegistration';
import UserRegistration from 'components/Registration/User';
import PasswordRecoveryStages from 'components/PasswordRecovery';

import LoginStages from 'components/Authorization';
import { getAccessExpiresOn, getConfirmationActionType, isTokenValid } from 'services/authUtils';
import { ElementWrapper } from 'routes/ElementWrapper';
import { ROUTE_PATHS, UNAUTHORIZED_ROUTE_PATHS, UNVERIFIED_ROUTE_PATHS } from 'routes/constants';
import { CUSTOMER_PERMISSIONS } from 'components/common/constants';
import Accounts from './Accounts/Accounts';
import DashBoard from './DashBoard/DashBoard';
import NewPayment from './NewPayment/NewPayment';
import BetweenAccounts from './BetweenAccounts/BetweenAccounts';
import Cards from './Cards/Cards';
import CardsDashboard from './Cards/components/CardsDashboard';
import CreateNewCardForm from './Cards/components/CreateNewCardForm';
import VirtualCardSettings from './Cards/components/VirtualCardSettings';
import CardsAuthorizations from './Cards/components/CardsAuthorizations';
import { Transactions } from './Transactions/Transactions';
import Messages from './Messages/Messages';
import MessagesDashboard from './Messages/components/MessagesDashboard';
import Settings from './Settings/Settings';
import Profile from './Profile/Profile';
import { setTokenRefresh } from '../services/requestAgent';
import CurrencyExchange from './CurrencyExchange/CurrencyExchange';
import Fees from './Fees/Fees';
import Templates from './Templates/Templates';
import TemplatesTable from './Templates/components/TemplatesTable/TemplatesTable';
import NewTemplateForm from './Templates/components/NewTemplateForm/NewTemplateForm';
import { NotFound } from './common/NotFound/NotFound';
import i18nContext from './i18n-context';
import Representatives from './Representatives/index';
import { clearAllParamsFromLocalStorage, extractBaseUrl, saveAllParamsToLocalStorage } from './utils';

function App({ authStore, userStore, messagesStore }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (searchParams.size > 0) {
      clearAllParamsFromLocalStorage();
      saveAllParamsToLocalStorage(searchParams);
    }
  }, []);

  useEffect(() => {
    if (
      isTokenValid() &&
      !authStore.isUserAlreadyRegistered &&
      (location.pathname !== ROUTE_PATHS.CUSTOMER_REGISTRATION ||
        (location.pathname === ROUTE_PATHS.CUSTOMER_REGISTRATION && getConfirmationActionType() !== 'undefined')) &&
      location.pathname !== ROUTE_PATHS.REGISTRATION_SUCCESS
    ) {
      const expiresOn = getAccessExpiresOn();
      setTokenRefresh((expiresOn - Date.now()) / 1000);

      // Fetch initial data
      if (!Object.keys(userStore.userData).length) {
        authStore.reset();
        userStore.loadUserData().then(() => {
          if (userStore.userData.account) {
            messagesStore.initializeData(userStore.userData.account.account_number);
          }
        });
        userStore.getAppState();
        // messagesStore.initializeData(userStore.userData.account_number);
      }
    }

    // Need to do some logic (API) to check user authorization status
    if (
      !isTokenValid() &&
      !Object.keys(UNAUTHORIZED_ROUTE_PATHS).filter((path) =>
        location.pathname.includes(UNAUTHORIZED_ROUTE_PATHS[path])
      ).length
    ) {
      navigate(ROUTE_PATHS.LOGIN);
    }

    // Redirection / -> /dashboard
    if (location.pathname === ROUTE_PATHS.ROOT) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    /* Redirection for unverified account.
     *  All routes (except UNVERIFIED_ROUTE_PATHS, UNAUTHORIZED_ROUTE_PATHS ) will be redirect to /dashboard
     */
    const baseUrl = extractBaseUrl(location.pathname);
    if (
      userStore.userData?.account &&
      userStore.isCustomerVerified !== null &&
      !userStore.isCustomerVerified &&
      !Object.values({ ...UNVERIFIED_ROUTE_PATHS, ...UNAUTHORIZED_ROUTE_PATHS }).includes(location.pathname) &&
      baseUrl !== ROUTE_PATHS.MESSAGES
    ) {
      navigate(ROUTE_PATHS.VERIFICATION);
    } else if ((userStore.isCustomerVerified || !userStore.userData?.account) && baseUrl === ROUTE_PATHS.VERIFICATION) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [navigate, location.pathname, userStore.isCustomerVerified]);

  useEffect(() => {
    if (
      userStore.isCustomerVerified &&
      userStore.userAccounts.length === 0 &&
      location.pathname !== ROUTE_PATHS.CUSTOMER_REGISTRATION
    ) {
      navigate(ROUTE_PATHS.DASHBOARD);
    }
  }, [userStore.userAccounts]);

  // Without any permissions access will be blocked for all shared accounts
  const isUserHasPermissions = userStore?.hasPermissions;

  return (
    <Routes>
      <Route path={ROUTE_PATHS.ROOT} element={<ApplicationLayout />}>
        <Route
          path={ROUTE_PATHS.DASHBOARD}
          element={
            <ElementWrapper
              isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}
              component={DashBoard}
              headerText={i18n.getMessage('menu.dashboard.text')}
            />
          }
        />
        <Route
          path={ROUTE_PATHS.VERIFICATION}
          element={
            <ElementWrapper
              isHasPermission={isUserHasPermissions()}
              component={DashBoard}
              headerText={i18n.getMessage('navigation.verification.text')}
            />
          }
        />
        <Route
          path={ROUTE_PATHS.MESSAGES}
          element={
            <ElementWrapper
              isHasPermission={isUserHasPermissions([
                CUSTOMER_PERMISSIONS.READ_MESSAGES,
                CUSTOMER_PERMISSIONS.CREATE_MESSAGE
              ])}
              component={Messages}
              headerText={i18n.getMessage('navigation.messages.text')}
            />
          }
        >
          <Route
            index
            element={
              <ElementWrapper
                isHasPermission={isUserHasPermissions([
                  CUSTOMER_PERMISSIONS.READ_MESSAGES,
                  CUSTOMER_PERMISSIONS.CREATE_MESSAGE
                ])}
                component={MessagesDashboard}
                headerText={i18n.getMessage('navigation.messages.text')}
              />
            }
          />
          <Route
            path={ROUTE_PATHS.MESSAGES_TOPIC}
            element={
              <ElementWrapper
                isHasPermission={isUserHasPermissions([
                  CUSTOMER_PERMISSIONS.READ_MESSAGES,
                  CUSTOMER_PERMISSIONS.CREATE_MESSAGE
                ])}
                component={MessagesDashboard}
                headerText={i18n.getMessage('navigation.messages.text')}
              />
            }
          />
          <Route
            path={ROUTE_PATHS.MESSAGES_CREATE_NEW_TOPIC}
            element={
              <ElementWrapper
                isHasPermission={isUserHasPermissions([
                  CUSTOMER_PERMISSIONS.READ_MESSAGES,
                  CUSTOMER_PERMISSIONS.CREATE_MESSAGE
                ])}
                component={MessagesDashboard}
                headerText={i18n.getMessage('navigation.messages.text')}
              />
            }
          />
        </Route>
        <Route
          path={ROUTE_PATHS.SETTINGS}
          element={
            <ElementWrapper
              isHasPermission={isUserHasPermissions(CUSTOMER_PERMISSIONS.READ_ACCOUNT)}
              component={Settings}
              headerText={i18n.getMessage('navigation.settings.text')}
            />
          }
        />
        <Route
          path={ROUTE_PATHS.PROFILE}
          element={
            <ElementWrapper
              isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_PROFILE])}
              component={Profile}
              headerText={i18n.getMessage('navigation.profile.text')}
            />
          }
        />
        {userStore.isCustomerVerified && (
          <>
            <Route
              path={ROUTE_PATHS.NEW_PAYMENT}
              element={
                <ElementWrapper
                  isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_TRANSACTION])}
                  component={NewPayment}
                  headerText={i18n.getMessage('container.sendMoney')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.BETWEEN_ACCOUNTS}
              element={
                <ElementWrapper
                  isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_INTERNAL_TRANSACTION])}
                  component={BetweenAccounts}
                  headerText={i18n.getMessage('container.betweenAccounts')}
                />
              }
            />
            {userStore.isCardsEnabled() && (
              <Route
                path={ROUTE_PATHS.CARDS}
                element={
                  <ElementWrapper
                    isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_CARDS])}
                    component={Cards}
                    headerText={i18n.getMessage('container.sendMoney')}
                  />
                }
              >
                <Route
                  index
                  element={
                    <ElementWrapper
                      isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_CARDS])}
                      component={CardsDashboard}
                      headerText={i18n.getMessage('container.createVirtualCard')}
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CARDS_CREATE}
                  element={
                    <ElementWrapper
                      isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_CARDS])}
                      component={CardsDashboard}
                      headerText={i18n.getMessage('container.createVirtualCard')}
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CARDS_CREATE_VIRTUAL}
                  element={
                    <ElementWrapper
                      isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_CARDS])}
                      component={CreateNewCardForm}
                      headerText={i18n.getMessage('container.createVirtualCard')}
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CARD_SETTINGS}
                  element={
                    <ElementWrapper
                      isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_CARDS])}
                      component={VirtualCardSettings}
                      headerText={i18n.getMessage('container.createVirtualCard')}
                    />
                  }
                />
                <Route
                  path={ROUTE_PATHS.CARDS_AUTHORIZATIONS}
                  element={
                    <ElementWrapper
                      isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}
                      component={CardsAuthorizations}
                      headerText={i18n.getMessage('container.authorizations')}
                    />
                  }
                />
              </Route>
            )}

            <Route
              path={ROUTE_PATHS.CURRENCY_EXCHANGE}
              element={
                <ElementWrapper
                  isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.CREATE_CURRENCY_EXCHANGE])}
                  component={CurrencyExchange}
                  headerText={i18n.getMessage('container.exchange')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.ACCOUNTS}
              element={
                <ElementWrapper
                  isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}
                  component={Accounts}
                  headerText={i18n.getMessage('container.accounts')}
                />
              }
            />
            <Route
              path={ROUTE_PATHS.TRANSACTIONS}
              element={
                <ElementWrapper
                  isHasPermission={isUserHasPermissions([
                    CUSTOMER_PERMISSIONS.READ_ACCOUNT,
                    CUSTOMER_PERMISSIONS.CREATE_REPORT
                  ])}
                  component={Transactions}
                  headerText={i18n.getMessage('container.Transactions')}
                />
              }
            />
            {/* Waiting for backend part */}
            {/*<Route*/}
            {/*  path={ROUTE_PATHS.TEMPLATES}*/}
            {/*  element={*/}
            {/*    <ElementWrapper*/}
            {/*      isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}*/}
            {/*      component={Templates}*/}
            {/*    />*/}
            {/*  }*/}
            {/*>*/}
            {/*  <Route*/}
            {/*    index*/}
            {/*    element={*/}
            {/*      <ElementWrapper*/}
            {/*        isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}*/}
            {/*        component={TemplatesTable}*/}
            {/*        headerText={i18n.getMessage('container.templates')}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*  <Route*/}
            {/*    path={ROUTE_PATHS.TEMPLATES_CREATE}*/}
            {/*    element={*/}
            {/*      <ElementWrapper*/}
            {/*        isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}*/}
            {/*        component={NewTemplateForm}*/}
            {/*        headerText={i18n.getMessage('container.createTemplate')}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*  <Route*/}
            {/*    path={ROUTE_PATHS.TEMPLATES_EDIT}*/}
            {/*    element={*/}
            {/*      <ElementWrapper*/}
            {/*        isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_ACCOUNT])}*/}
            {/*        component={NewTemplateForm}*/}
            {/*        headerText={i18n.getMessage('container.editTemplate')}*/}
            {/*      />*/}
            {/*    }*/}
            {/*  />*/}
            {/*</Route>*/}
            {/* Waiting for backend part */}
          </>
        )}

        {userStore.isFeesEnabled() && (
          <Route
            path={ROUTE_PATHS.FEES}
            element={
              <ElementWrapper
                isHasPermission={isUserHasPermissions([CUSTOMER_PERMISSIONS.READ_FEES])}
                component={Fees}
                headerText={i18n.getMessage('container.fees')}
              />
            }
          />
        )}
        {userStore.isRepresentativesEnabled() && (
          <Route
            path={ROUTE_PATHS.REPRESENTATIVES}
            element={
              <ElementWrapper
                isHasPermission={isUserHasPermissions()}
                component={Representatives}
                headerText={i18n.getMessage('container.representatives')}
              />
            }
          />
        )}
      </Route>
      <Route path={ROUTE_PATHS.PASSWORD_RECOVERY} element={<ElementWrapper component={AuthorizationLayout} />}>
        <Route index element={<ElementWrapper component={PasswordRecoveryStages} />} />
        <Route path={'email/confirm/:tokenId'} element={<ElementWrapper component={PasswordRecoveryStages} />} />
      </Route>
      <Route path={ROUTE_PATHS.LOGIN} element={<AuthorizationLayout isRegistration={false} />}>
        <Route index element={<ElementWrapper component={LoginStages} />} />
      </Route>
      <Route path={ROUTE_PATHS.REGISTRATION} element={<AuthorizationLayout isRegistration={true} />}>
        <Route index element={<ElementWrapper component={UserRegistration} />} />
      </Route>
      <Route path={ROUTE_PATHS.CUSTOMER_REGISTRATION} element={<AuthorizationLayout isRegistration={true} />}>
        <Route index element={<ElementWrapper component={AccountRegistration} />} />
      </Route>
      <Route path={ROUTE_PATHS.REGISTRATION_SUCCESS} element={<AuthorizationLayout />}>
        <Route index element={<ElementWrapper component={SuccessAccountRegistration} isRegistration={true} />} />
      </Route>
      {userStore.isCustomerVerified && <Route path={'*'} element={<NotFound />} />}
    </Routes>
  );
}

App.propTypes = {
  authStore: MobXPropTypes.observableObject,
  userStore: MobXPropTypes.observableObject,
  messagesStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  authStore: stores.authStore,
  userStore: stores.userStore,
  messagesStore: stores.messagesStore
}))(observer(App));
