import i18n from 'i18n';
import * as Yup from 'yup';
import { PAYMENT_PROVIDERS } from 'components/common/constants';
import { passwordRegExp, registrationPasswordRegExp } from 'services/utils';
import moment from 'moment-timezone';

const dateOpts = {
  year: 'numeric',
  month: 'short',
  day: '2-digit'
};

const timeOpts = {
  hour: '2-digit',
  minute: '2-digit'
};

export const parseDate = (data) => {
  const date = new Date(data);

  return {
    date: date.toLocaleString('en-UK', dateOpts),
    time: date.toLocaleString('en-UK', timeOpts) + ' GB'
  };
};

export const convertGBtoUTC = (gbDate) => {
  return moment.tz(gbDate, 'Europe/London').utc().format();
};

export const convertUTCtoGB = (utcDate) => {
  const momentUtcDate = moment.utc(utcDate);
  const convertedGBDate = moment.tz(momentUtcDate, 'Europe/London');

  const date = moment(convertedGBDate).format('D MMM YYYY');
  const time = moment(convertedGBDate).format('HH:mm') + ' GB';

  return { date, time };
};

export const getAccountTitle = (account) => {
  return account.transfer_provider === PAYMENT_PROVIDERS.WALLESTER
    ? i18n.getMessage('account.title.cardAccount', { accountNumber: account.wallet_number })
    : account.transfer_provider === PAYMENT_PROVIDERS.MANUAL
      ? i18n.getMessage('account.title.manual', { accountNumber: account.wallet_number })
      : account.multi_wallet_number
        ? account.multi_iban?.iban || account.multi_wallet_number
        : account.iban?.iban || account.wallet_number;
};

export const getAccountsOptionsForSelect = (accounts) =>
  accounts?.map((account) => {
    const title = getAccountTitle(account);

    return {
      key: account?.wallet_number,
      value: title,
      currency: account?.currency
    };
  });

export const extractBaseUrl = (url) => {
  const parts = url.split('/');
  return `/${parts.slice(1, 2).join('/')}`;
};

export const getPasswordValidation = () => {
  return Yup.string()
    .nullable(true)
    .min(7, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
    .max(30, i18n.getMessage('error.PASSWORD_REQUIREMENTS'))
    .required(i18n.getMessage('error.passwordEmpty'))
    .matches(passwordRegExp, { message: i18n.getMessage('error.PASSWORD_REQUIREMENTS') });
};

export const getRegistrationPasswordValidation = () => {
  return Yup.string()
    .nullable(true)
    .required(i18n.getMessage('error.passwordEmpty'))
    .test(
      'valid-characters',
      i18n.getMessage('error.REGISTRATION_PASSWORD_REQUIREMENTS'),
      (value) => !/[^\x20-\x7E]/.test(value)
    );
};

export const isFullSecurityCode = (code) => {
  const regex = /^\w{6}$/;
  return regex.test(code);
};

export const hasMultipleCurrencies = (userAccounts) => {
  if (userAccounts.length < 2) return false;

  const uniqueCurrencies = new Set();

  for (const account of userAccounts) {
    uniqueCurrencies.add(account.currency);
  }

  return uniqueCurrencies.size > 1;
};

export const saveAllParamsToLocalStorage = (params) => {
  const allParams = Array.from(params.entries()).reduce((accumulator, [key, value]) => {
    accumulator[key] = value;
    return accumulator;
  }, {});
  localStorage.setItem('allParams', JSON.stringify(allParams));
};

export const getAllParamsFromLocalStorage = () => {
  const storedParams = localStorage.getItem('allParams');
  return storedParams ? JSON.parse(storedParams) : {};
};

export const clearAllParamsFromLocalStorage = () => {
  localStorage.removeItem('allParams');
};

export const base64ToImageUrl = (base64Data) => {
  return 'data:image/png;base64,' + base64Data;
};

export const getFirstCreatedAccountDate = (accounts) => {
  if (!accounts || accounts.length === 0) {
    return undefined;
  }

  let earliestDate = new Date(accounts[0].created_at);

  accounts.forEach((account) => {
    const accountDate = new Date(account.created_at);
    if (accountDate < earliestDate) {
      earliestDate = accountDate;
    }
  });

  return earliestDate;
};
